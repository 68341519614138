.profile-page {
  .profile-info {
    .title_container {
      margin-bottom: 15px;
      .radio_label {
        label:before {
          margin: 0 5px 5px;
        }
      }
    }
  }
  .newsletter-info {
    &__header {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-weight: normal;
      line-height: 1;
      font-size: 24px;
      margin-top: 0;
    }
    &__item {
      &:first-child {
        border: none;
      }
    }
    .profile-my-store {
      &__find-a-store {
        .selectBox {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 500px;
          }
        }
      }
      &__store {
        @media (width: $medium-up) {
          padding-left: 420px;
          &-info {
            font-size: 13px;
          }
          &-map-container {
            width: 420px;
          }
          &-actions {
            .button {
              letter-spacing: 0;
              font-size: 13px;
              padding: 0 5px;
            }
          }
        }
      }
    }
  }
}
