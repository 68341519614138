.change-password {
  .sign-in-page {
    .password-field {
      overflow: visible;
      &__info {
        @include breakpoint($medium-up) {
          right: 98%;
          bottom: 5%;
        }
      }
    }
  }
  .checkout-confirmation-page {
    .password-field {
      &__info {
        @include breakpoint($medium-up) {
          &-checkout {
            bottom: 80%;
          }
        }
      }
    }
  }
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__rules {
          li {
            display: flex;
          }
        }
      }
    }
  }
}
