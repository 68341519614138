#shipping-panel {
  .info-gdpr-wrap {
    overflow: auto;
    .info-gdpr-required,
    .info-link-gdpr[message-in='account-signin-text'] {
      font-size: 14px;
    }
    .info-div-gdpr {
      float: left;
      .info-link-gdpr {
        margin-right: 5px;
      }
      .info-icon-gdpr {
        padding: 1px 0 0 10px;
        text-decoration: none;
      }
    }
  }
  .select-address {
    .field-container {
      padding-bottom: 1em;
      @include breakpoint($xsmall-up) {
        padding-right: 1.33em;
      }
    }
  }
  .default-shipping {
    label {
      display: block;
    }
  }
}
