.favorites {
  &-page {
    &__inline-tab {
      @include breakpoint($small-down) {
        display: inline-block;
      }
    }
  }
  &__instruction-step-number {
    font-size: 1.5em;
  }
  &__instruction-step-desc {
    font-size: 1.17em;
  }
  &__sample-list-notice {
    width: 65%;
    margin: 1em auto 0;
    text-align: center;
  }
}
