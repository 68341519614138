.tout-carousel-formatter {
  margin: 0 auto;
  position: relative;
  width: 80%;
  .carousel-controls .slick-arrow {
    background-color: transparent;
    background-image: url('/media/export/cms/global/carousel.arrows.png');
    background-repeat: no-repeat;
    height: 55px;
    margin: -27px 0 0 0;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    width: 27px;
    &.slick-prev {
      left: 20px;
    }
    &.slick-next {
      background-position: right 10px;
      right: 20px;
    }
  }
  .slick-list {
    margin: 0 43px;
    .slick-track {
      margin: 0 auto;
    }
  }
  .tout-carousel-formatter_items {
    box-sizing: border-box;
    width: 100%;
    .tout-carousel-formatter__slide {
      display: inline-block;
      max-width: 100%;
      width: 100%;
      .gnav-menu-tout {
        .gnav-formatter--lvl-2 & {
          font-family: $font--futura-demi;
          font-size: 14px;
          margin: 0 15px;
          width: auto;
        }
        .gnav-menu-tout__image {
          vertical-align: top;
        }
        .gnav-menu-tout__text {
          line-height: 20px;
          height: 20px;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
}
