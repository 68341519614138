.order-details-page {
  .order-products {
    &__header {
      &--status,
      &--price,
      &--qty,
      &--total {
        @include breakpoint($large-up) {
          text-align: center;
        }
      }
      &--tracking {
        width: 19%;
        margin-right: 8px;
      }
    }
    .account-products {
      &__item {
        &--desc {
          @include breakpoint($large-up) {
            width: 27%;
          }
        }
      }
    }
    .product {
      &__status,
      &__price,
      &__qty,
      &__total {
        @include breakpoint($large-up) {
          text-align: center;
        }
      }
      &__qty {
        margin-top: 3px;
      }
      &__status {
        margin-top: 0;
      }
    }
  }
  .order-totals {
    @include breakpoint($large-up) {
      padding-right: 2.5em;
    }
    @include breakpoint($portrait-down) {
      padding-left: initial;
    }
    td {
      vertical-align: top;
    }
  }
}
