.checkout__sidebar {
  .cart-items {
    &__item {
      @include breakpoint($portrait-down) {
        padding-left: 0;
      }
      &--qty {
        white-space: nowrap;
        @include breakpoint($portrait-down) {
          font-size: 14px;
        }
      }
    }
  }
}
