#photo-upload-query {
  #file-chosen-state {
    margin-top: 10px;
    text-align: left;
    border: none;
    max-width: 65%;
    @include breakpoint($xxsmall-up) {
      max-width: 70%;
    }
  }
  .upload-file {
    display: none;
  }
  .btn-instructions {
    overflow: auto;
    float: none;
    width: auto;
    margin: 20px 0 0;
  }
  #choose-file-btn {
    float: none;
    padding: 14px;
    background: url(/media/customer_service/live_chat/choose_file_blank.png) no-repeat;
  }
  .info-div-gdpr {
    float: left;
    margin-top: 10px;
    text-transform: none;
  }
  .msg-instructions {
    width: auto;
    max-width: 400px;
  }
  .profile-pic-upload__form-wrapper {
    margin-left: 25px;
  }
}

.profile-pic-upload {
  &__form-wrapper {
    .msg-instructions {
      .info-div-gdpr {
        margin: 10px 10% 0 0;
      }
    }
  }
  .upload-file__value {
    display: block;
  }
}

.address-overlay,
.sign-in-page,
.profile-page {
  .info-gdpr-required,
  .info-link-gdpr[message-in='account-signin-text'] {
    font-size: 14px;
  }
  .info-div-gdpr {
    float: left;
    .info-link-gdpr {
      margin-right: 5px;
    }
    .info-icon-gdpr {
      padding: 1px 0 0 10px;
      text-decoration: none;
    }
  }
}
