.address-book-page {
  .address-book {
    .address-item {
      float: none;
    }
  }
}

.sign-in-page {
  .account-main-content {
    .account-page__section--heading {
      display: block;
      color: $color-black;
      font-size: 28px;
      text-align: center;
      font-style: normal;
      text-transform: uppercase;
    }
    .new-account__fieldset {
      margin-top: -20px;
      label {
        &::before {
          margin-top: 1px;
        }
      }
    }
  }
}

.favorites {
  &-page {
    .link {
      text-transform: none;
    }
  }
  &-all {
    .favorites__list-actions {
      @include breakpoint($medium-up) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 0;
        &-order-by {
          position: static;
        }
        &-link--add-all {
          margin-right: 36px;
        }
      }
    }
  }
}
