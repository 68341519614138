.orders-list {
  .order-details {
    .product {
      &__tracking {
        @include breakpoint($large-up) {
          width: 17%;
          margin-left: 3px;
        }
      }
      &__qty {
        width: 14%;
        @include breakpoint($xsmall-down) {
          width: 66%;
          margin-top: 5%;
        }
      }
      &__reorder {
        a {
          height: 37px;
          line-height: 2.5em;
        }
      }
      &__actions {
        @include breakpoint($portrait-down) {
          width: 25%;
        }
        @include breakpoint($xsmall-down) {
          margin-top: 5%;
        }
      }
    }
    .account-products__item {
      &--desc {
        margin: inherit;
        @include breakpoint($medium-portrait-only) {
          width: 40%;
          margin-left: 3px;
        }
      }
    }
  }
  &__table--cell {
    &.order-number,
    &.order-status,
    &.order-cost {
      @include breakpoint($medium-portrait-only) {
        width: 20%;
      }
    }
  }
}
