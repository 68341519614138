.page-header {
  &.alt-nav-mob {
    div.utility-nav {
      &__cart {
        .cart-item {
          &__subheading {
            font-size: 16px;
            margin: 0 0 3px;
          }
        }
      }
      float: right;
      .gnav-util__icon {
        span.utility-nav__cart-count {
          left: 39%;
          @include breakpoint($landscape-up) {
            left: 50%;
          }
        }
      }
      .cart-block-wrapper {
        &.loading {
          .cart-block {
            visibility: visible;
          }
        }
      }
      &__account-button {
        a.utility-nav__account-text-link {
          @include breakpoint($xxsmall-down) {
            font-size: 12px;
          }
          @include breakpoint($portrait-down) {
            max-width: 70px;
          }
        }
      }
      .cart-block {
        &__footer {
          &__price-legal {
            float: right;
            clear: both;
          }
        }
      }
    }
    div.utility-nav-mob {
      &__item {
        a {
          @include breakpoint($xxsmall-down) {
            font-size: 12px;
          }
        }
      }
    }
  }
  &__nav-inner-top {
    .utility-nav {
      &__loyalty {
        .site-email-signup {
          &__success-cta {
            a {
              line-height: 4;
            }
            a:hover {
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

input {
  &.js-label-mode {
    & + label:before {
      content: attr(alt) !important;
    }
  }
}

.page-content {
  .cs-page {
    .tout-tile {
      min-height: 240px;
    }
  }
}

.waitlist-form {
  &__header {
    margin-top: 0;
    &.temp-out-of-stock {
      font-size: 25px;
      padding-top: 0;
    }
  }
}

#main-focus-content {
  .store-locator__filter-item:nth-child(2n + 1) {
    clear: left;
  }
  .store-locator__result {
    &-content {
      padding-left: 125px;
      @include breakpoint($xxsmall-down) {
        font-size: 15px;
      }
      @include breakpoint($xsmall-up $small-down) {
        padding-left: 140px;
      }
    }
    &-address-line {
      @include breakpoint($xxsmall-down) {
        width: 70%;
      }
    }
    .distance {
      @include breakpoint($xxsmall-down) {
        width: 30%;
      }
    }
  }
}

.utility-nav__loyalty {
  &.loyalty_email_signup {
    @include breakpoint($medium-down) {
      display: none;
    }
  }
  .menu {
    &__link--lvl-1 {
      &.menu {
        &__link--has-children {
          @include breakpoint($medium-up) {
            line-height: 1.2;
          }
        }
      }
    }
  }
}

.welcome-15 {
  .site-email-signup__success-header {
    @include breakpoint($small-down) {
      font-size: 50px;
    }
  }
}

div.spp-page {
  &.spp-product-layout-v2 {
    .product-full__overview-trigger {
      @include breakpoint($medium-down) {
        margin: 0 0.5em 0 0;
      }
    }
  }
}

.section-chatprivacypolicy {
  .page-header,
  .page-footer {
    display: none;
  }
  // !important was used because the live chat styles are controlled by LivePerson as inline.
  .LPMcontainer {
    display: none !important;
  }
  .page-wrapper {
    padding: 5px !important;
  }
}

.regimen-finder .regimen-finder-result-page__menu_item .menu--sub-header {
  font-size: 17px;
  margin: 0 0 17px 0;
  height: 74px;
  @include breakpoint($medium-up) {
    width: 250px;
    margin: 0 0 10px 50px;
    height: 38px;
    font-size: 18px;
  }
}

.quiz-landing-page-formatter .quiz-landing-page-formatter__results .quiz-landing-page-formatter__body .retake-quiz {
  width: 36%;
  font-size: 14px;
  @include breakpoint($medium-up) {
    width: 13%;
    font-size: 17px;
  }
}

.quiz-landing-page-formatter
  .quiz-landing-page-formatter__results
  .quiz-landing-page-formatter__body
  .product__button--add-to-bag {
  width: 63%;
  font-size: 13px;
  @include breakpoint($medium-up) {
    width: auto;
    font-size: 14px;
  }
}

.style--bare {
  font-size: 15px;
  line-height: 1.1;
  @include breakpoint($medium-up) {
    font-size: 24px;
  }
}

.regimen-finder .regimen-finder-result-page__menu_item .regimen-finder-result-page__menu--content .menu--header {
  font-size: 15px;
  @include breakpoint($medium-up) {
    font-size: 35px;
  }
}

.quiz-landing-page-formatter__body {
  .regimen-finder-result-page__items {
    .regimen-finder-result-page__menu_item {
      @include breakpoint($medium-up) {
        width: 32%;
      }
    }
  }
}

div.product-brief__shades {
  .slick-next {
    @include breakpoint($medium-up) {
      left: 206px;
    }
  }
}

div.product-brief.product-brief-v2 {
  .product-brief__cta-add-to-bag {
    .product-add-to-bag {
      font-size: 13px;
      padding: 0 8px;
    }
  }
  div.product-brief__price {
    @include breakpoint($medium-up) {
      display: inline-block;
    }
  }
}

.field-content {
  .quiz-landing-page-formatter {
    .form-formatter[data-form-style='show_single'] {
      .form-formatter__items {
        .back--button {
          @include breakpoint($medium-up) {
            width: 115px;
          }
          @include breakpoint($medium-down) {
            .button {
              &:before {
                top: 12px;
                border-width: 8px;
              }
              &:after {
                border-width: 11.5px;
                top: 8px;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}

.change-password {
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__rules {
          li {
            display: flex;
          }
        }
      }
    }
  }
}
#samples-panel {
  header {
    h2 {
      @include breakpoint($medium-down) {
        margin: 43px auto -24px;
      }
    }
  }
}
// Endeca Updated Search
.utility-nav-active,
.enable-endeca__search {
  .search_results__filter_clear {
    p {
      a.button {
        width: auto;
      }
    }
  }
}

.checkout {
  .messages {
    &.error {
      background-color: transparent;
      color: $color-red;
    }
  }
}

.block-template-gnav-menu-tout-v1 {
  .tout-carousel-formatter {
    &__slide {
      .gnav-menu-tout {
        width: auto;
      }
    }
    .tout-carousel-formatter_items {
      .tout-carousel-formatter {
        &__slide {
          .gnav-menu-tout {
            &__text {
              height: auto;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($landscape-up) {
  .gnav-menu-tout {
    &__image {
      display: inline-block;
    }
  }
}
