.site-email-signup__success-cta {
  .button {
    line-height: 56px;
  }
}

header {
  &.page-header {
    &.alt-nav-mob {
      .gnav-util--search-v2 {
        &.gnav-util {
          .gnav-util__content__inner {
            form.gnav-search {
              input[type='search'] {
                @include breakpoint($medium-up) {
                  margin-top: 58px;
                }
                @include breakpoint($landscape-up) {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.utility-nav__item {
  &.mobile-hidden {
    // .mobile-hidden class hides elements up to 768px using !important.
    // We want to hide this element for devices up to 1024px.
    @include breakpoint($medium-up) {
      display: none !important;
    }
    @include breakpoint($landscape-up) {
      display: block !important;
    }
  }
}
