/* Info popup styles for privacy policy */
.info-link-gdpr {
  cursor: pointer;
  margin-bottom: 10px;
  border: 0px;
  display: block;
  text-decoration: underline;
}

.info-icon-gdpr {
  cursor: pointer;
  background: $color-black;
  color: $color-white;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  display: block;
  text-transform: lowercase;
  padding: 1px 0px 0px 9px;
}

.info-message-txt-gdpr {
  display: none;
}

.info-msg-gdpr {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: $color-white;
  padding: 20px;
  width: 90%;
  border: 1px solid $color-black;
  max-width: 500px;
  margin-left: 2px;
  &__arrow {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: 0px;
    border: 10px solid transparent;
    border-bottom-color: $color-black;
    top: -10px;
    margin-top: -10px;
  }
  &.top-right-arrow {
    &:before {
      left: 478px; /* 500px minus Border 2px and Triangle 20px */
    }
  }
  &__txt {
    position: relative;
  }
  &__close {
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
  a {
    text-decoration: underline;
    cursor: pointer;
  }
  &__address-legal-text-gdpr {
    cursor: pointer;
    text-decoration: underline;
  }
}

#registration_short {
  .form-item.new-account__item.checkbox.password {
    .picker.picker-checkbox,
    .password-notice {
      float: left;
    }
    .password-notice {
      margin: 10px 0px 0px 10px;
    }
  }
}

.info-gdpr-for-left,
.info-gdpr-right-icon {
  float: left;
}

.info-gdpr-right-icon {
  margin-left: 10px;
}

.ecommerce {
  .welcome-15 {
    &__site-email-signup-gdpr {
      padding-top: 79px;
      text-align: left;
      margin-left: 109px;
      text-decoration: underline;
    }
  }
  .site-email-signup {
    &__email-signup-gdpr {
      padding-top: 66px;
      text-align: left;
      text-decoration: underline;
      margin-left: 0px;
    }
  }
}

.site-email-signup {
  &__email-signup-gdpr {
    text-align: left;
    text-decoration: underline;
    margin-left: 65px;
  }
}

.shipping_wrapper {
  .error_messages {
    .optin-errormsg-gdpr {
      color: $color-red;
    }
  }
}

.shipping,
.review {
  .error_messages {
    .optin-errormsg-gdpr {
      text-decoration: underline;
    }
  }
}

.waitlist-form-legal {
  .info-link-gdpr {
    text-align: left;
    margin-left: 28px;
  }
}
