.viewcart {
  .checkout__content {
    #viewcart-panel {
      .cart-items {
        @include breakpoint($medium-up) {
          &__item--thumb {
            width: 15%;
          }
          &__item--qty {
            clear: none;
          }
          &__item--price {
            width: 15%;
            float: left;
            margin-left: 0;
          }
        }
        &__item--remove-form {
          .remove_link {
            @include breakpoint($medium-down) {
              &.pc-hidden {
                display: block !important;
              }
              &.mobile-hidden {
                display: none !important;
              }
            }
            @include breakpoint($medium-up) {
              right: 0;
              position: relative;
            }
          }
        }
      }
    }
  }
  .checkout__panel--continue-buttons {
    .continue-checkout {
      @include breakpoint($portrait-down) {
        width: 100%;
      }
    }
  }
}

#top {
  .gnav-util__content-icon {
    @include breakpoint($medium-up) {
      top: 5px;
    }
  }
  form.gnav-search {
    input[type='search'] {
      @include breakpoint($medium-up) {
        margin-top: 32px;
      }
      @include breakpoint($portrait-up) {
        margin-top: -66px;
      }
      @include breakpoint($large-up) {
        margin-top: 0;
      }
    }
  }
}
