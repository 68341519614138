.checkout {
  margin-top: 0;
  .checkout__subtitle {
    font-size: 18px;
  }
  &__panel {
    &--continue-buttons {
      a.continue-checkout {
        @include breakpoint($small-down) {
          width: 100%;
        }
      }
    }
  }
  div.checkout_sticky {
    height: auto;
    .mobile_sticky_bottom {
      position: relative;
      margin: 10px auto;
    }
  }
  &.viewcart {
    @include breakpoint($portrait-down) {
      margin-top: 55px;
    }
    .checkout-header__title__count {
      display: inline-block;
    }
    #viewcart-panel {
      div.cart-items {
        &__item {
          &--price {
            margin-right: 0;
            @include breakpoint($small-down) {
              text-align: left;
            }
            @include breakpoint($medium-down) {
              width: 15%;
            }
          }
          &--qty {
            margin: 0;
          }
          &--total {
            width: 15%;
            text-align: center;
            position: relative;
            top: 0;
            right: 0;
            padding-left: 0;
            @include breakpoint($small-down) {
              width: 20%;
            }
          }
        }
        &__header {
          &--price,
          &--qty,
          &--total {
            width: 15%;
            padding-left: 0;
            text-align: center;
          }
        }
      }
      .product_contains {
        font-size: 14px;
      }
    }
  }
  &.panel,
  &.samples-page,
  &.checkout-confirmation-page {
    @include breakpoint($portrait-down) {
      margin-top: 55px;
    }
    @include breakpoint($small-down) {
      margin-top: 0;
    }
  }
  &__content {
    fieldset {
      overflow: visible;
    }
    #promo-message {
      margin: 15px 18px 0;
    }
  }
  &__sidebar {
    .order-summary-panel {
      &__label,
      &__value {
        @include breakpoint($portrait-down) {
          font-size: 17px;
          line-height: inherit;
        }
      }
      &__label {
        width: 75%;
      }
      &__value {
        width: 25%;
      }
      #checkout_shipmethod {
        margin-top: 0.5em;
        .ship-method {
          select {
            @include breakpoint($medium-down) {
              min-width: 220px;
              font-size: 13px;
            }
          }
        }
      }
    }
    .cart-items__item {
      &--total,
      &--product-subname {
        @include breakpoint($portrait-down) {
          font-size: 17px;
        }
      }
    }
  }
  #sign-in-panel {
    #new-account,
    #return-user {
      @include breakpoint($medium-only) {
        min-height: 350px;
      }
    }
  }
}

#shipping-panel {
  .address-to-use {
    .ship_group_1 {
      label {
        display: initial;
      }
    }
  }
  fieldset {
    .form-item {
      &.country-id,
      &.bill-to-shipping {
        padding-bottom: 0;
      }
    }
    &.fs {
      margin: 0 0 15px;
      p {
        margin-top: 0;
      }
    }
  }
  #continue-btn {
    @include breakpoint($medium-up) {
      margin-bottom: 1em;
    }
  }
  #gift-options-display {
    margin-bottom: 1em;
    #gift-options-h,
    #gift-message-h {
      margin: 1em 0 0;
    }
  }
}

#shopping-bag-panel {
  header {
    float: left;
  }
  .shopping-bag-panel__item-count {
    line-height: 1.5em;
    float: right;
  }
}

#offer-code-panel {
  padding: 1em;
  .checkout__panel--content {
    padding: 0;
  }
  .checkout__panel--header {
    padding: 0;
  }
  .checkout__panel--heading {
    @include breakpoint($small-down) {
      margin-top: 0.33em;
      .expando-block__icon {
        position: absolute;
        top: 6px;
        right: 5px;
      }
    }
  }
  .expando-block__icon {
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  .offer-code__form {
    label {
      font-size: 16px;
      margin-top: -3.25em;
    }
  }
}

p {
  font-size: 15px;
}

#review-panel {
  form#checkout_complete {
    padding: 1em 0;
    .payment-type {
      overflow: auto;
      &.direct-debit {
        select,
        input {
          margin-bottom: 15px;
          width: 100%;
        }
      }
    }
    label:first-child {
      display: none;
    }
    .picker {
      margin: 0.5em 1em 1em 0;
      &-radio {
        @include breakpoint($large-only) {
          float: left;
        }
      }
      &-label {
        &.error {
          color: $color-red;
        }
      }
    }
  }
  .view-address {
    & > a {
      float: right;
      font-size: 14px;
      line-height: 1.5rem;
      text-transform: uppercase;
    }
  }
  .messages {
    &.error {
      padding-bottom: 1.5em;
    }
  }
}

.adpl {
  div.invalid_marker {
    right: 20px;
    @include breakpoint($xsmall-up) {
      right: 47px;
    }
  }
  .country-id {
    label {
      display: block;
    }
  }
}

#cboxLoadedContent {
  padding-top: 1.5em;
  .expando-block {
    &__header,
    &__content {
      padding: 0 1.5em;
      height: auto;
    }
    &__title--mobile {
      display: none;
    }
    &__title--pc {
      @include breakpoint($medium-down) {
        display: block;
      }
    }
  }
}

#confirmation-page {
  .password {
    margin: 10px 0;
  }
}

.page-footer {
  .footer-checkout {
    .icon {
      font-family: FontAwesome;
    }
  }
}

.payment_frame {
  width: 100%;
  padding: 10px;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  @include breakpoint($large-up) {
    -webkit-overflow-scrolling: auto;
  }
  #gc_iframe {
    width: 100%;
    height: 450px;
  }
}

#delivery-address-h {
  @include breakpoint($xxsmall-down) {
    width: 75%;
  }
}

.confirm {
  .checkout-confirmation-page {
    .checkout {
      &__sidebar {
        display: block;
      }
    }
  }
}
