.optanon-alert-box-bg {
  div.optanon-alert-box-body {
    @include breakpoint($medium-up) {
      margin: 0 40px;
    }
  }
  div.optanon-alert-box-button-container {
    @include breakpoint($small-down) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
    @include breakpoint($medium-up) {
      position: relative;
      margin-top: 5px;
    }
    .optanon-alert-box-button {
      @include breakpoint($small-down) {
        width: 75%;
        padding: 0;
        &-middle {
          padding: 8px 0;
          text-align: center;
          border: none;
        }
        button {
          text-align: center;
          border: none;
        }
      }
    }
  }
}

#optanon {
  input[type='checkbox'] ~ label:before {
    content: none;
  }
  #optanon-popup-bottom-logo {
    display: none;
  }
  @include breakpoint($small-down) {
    &-popup-bottom {
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
      &-logo {
        display: none;
      }
    }
    #optanon-popup-wrapper {
      div.optanon-button-wrapper {
        margin: 9px 10px 0px 9px;
      }
    }
  }
}

.optanon-alert-box-wrapper {
  .optanon-alert-box-corner-close {
    button {
      @include breakpoint($small-down) {
        z-index: 5;
      }
    }
  }
}
