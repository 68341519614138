.write_a_review__login {
  .full_sign_in {
    h2 {
      font-size: 16px;
    }
  }
}

.spp-sticky-add-to-bag {
  &__add-to-bag {
    width: auto;
    margin-left: 10px;
  }
}

#colorbox {
  #cboxLoadedContent {
    padding-top: 0;
    .product-fave-confirm {
      &__copy {
        p {
          font-size: 24px;
        }
      }
    }
  }
}

@include breakpoint($medium-up) {
  .customer-service-contact-form {
    input[type='text'] {
      width: 45%;
      margin-right: 5%;
    }
    &_select-wrapper {
      width: 45%;
    }
    &__section {
      textarea {
        width: 95%;
        margin-right: 5%;
      }
    }
  }
}

@include breakpoint($medium-up) {
  .mpp.mpp-v2 {
    .product-grid {
      &__content {
        margin: 0;
      }
    }
  }
  .mpp-filter-set {
    &__container {
      padding: 0 0.5em;
    }
  }
  .mpp-sort.mpp-sort-v2 {
    .mpp-sort__section {
      margin: 0;
      padding: 0 0.5em 0 0;
    }
  }
}

#lpChat {
  .lp_radio_button {
    input[type='radio'] {
      display: none;
    }
  }
}

.cs-page {
  .expando-menu {
    &__static,
    &-trigger {
      @include breakpoint($medium-up $medium-down) {
        font-size: 16px;
      }
    }
  }
  &__navigation {
    .cs-contacts {
      &__cta {
        @include breakpoint($medium-up $medium-down) {
          word-break: break-word;
        }
      }
    }
  }
}

.product-full {
  &.product-full-v2 {
    .product-full__tabbed-content {
      @include breakpoint($medium-up) {
        overflow-y: auto;
      }
    }
  }
}

.product-grid {
  &__content {
    .product-grid__item {
      .product {
        &__inventory-status {
          display: none;
        }
      }
      .product-add-to-waitlist {
        height: 30px;
      }
    }
  }
}

.search_results__container {
  .close_x {
    display: inline-block;
  }
}

.waitlist {
  &-tooltip {
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    .tooltiptext-over {
      position: absolute;
      width: 100%;
      max-width: 400px;
      left: 0;
      bottom: 125%;
      visibility: hidden;
      background-color: $color-white;
      color: $color-dark-gray;
      border: 1px solid $color-mid-gray;
      border-radius: 6px;
      padding: 10px;
      z-index: 1;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:hover {
      .tooltiptext-over {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
