.checkout-confirmation-page {
  .form-item {
    .password {
      position: relative;
      .invalid_marker {
        top: 25%;
        @include breakpoint($medium-up) {
          top: 15%;
        }
      }
    }
    .text {
      margin: 10px 0;
    }
  }
  a.terms_privacy {
    text-decoration: underline;
  }
}

.picker {
  .picker-label {
    &.error {
      color: $color-red;
    }
  }
}
